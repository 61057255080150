<template>
  <div>
    <h3 class="primary--text">{{ question }}</h3>
    <br>
    <v-row
      justify="center"
    >
      <v-col
        class="no-padding"
        cols="12"
        sm="6"
        v-for="choice in choices"
        :key="'col'+choice.index"
      >
        <Choice
          :key="'choice'+choice.index"
          :item="choice"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Additive',
  components: {
    Choice: lazyLoad('components/Cards/Choice/Index'),
  },
  computed: {
    ...mapGetters('testMicro', [
      'step',
      'selected',
      'selectedIndex',
    ]),
    overlaysPreSelect() {
      const { choices } = this.step;
      const { length } = Object.keys(choices);
      return Array(length).fill(false);
    },
    overlaysPostSelect() {
      const overlays = Array.from(this.overlaysPreSelect);
      overlays.splice(this.selectedIndex, 1, true);
      const invOverlays = overlays.map((bool) => (!bool));
      return invOverlays;
    },
    question() {
      const { question } = this.step;
      return question;
    },
    choices() {
      const { choices } = this.step;
      const keys = Object.keys(choices);
      const overlays = this.selected ? this.overlaysPostSelect : this.overlaysPreSelect;
      const result = keys.map((index) => ({
        index,
        text: choices[index],
        overlay: overlays[index],
      }));
      return result;
    },
  },
};
</script>
